.main {
  width: 90%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.25rem 0;
}

.img {
  background-color: unset;
  width: 40px;
  padding: 0.3rem;
  object-fit: cover;
  object-position: center;
  height: 40px;
}
[data-theme="light"] {
  .img {
    background-color: unset;
    width: 40px;
    padding: 0.3rem;
    object-fit: cover;
    object-position: center;
    height: 40px;
  }
}
[data-theme="dark"] {
  .img {
    background-color: var(--text-color);
    width: 40px;
    padding: 0.3rem;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
    height: 40px;
  }
}
.text {
  margin: 0 0.5rem;
  font-size: 1rem;
  color: var(--text-color);
  /* text-transform: uppercase; */
  /* font-weight: bold; */
}
.editable {
  width: 100px;
}

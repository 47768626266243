@media all and (min-width: 700px) {
  .main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .items {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    page-break-after: always;
  }
}
@media all and (max-width: 700px) {
  .main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    min-height: fit-content;
  }
  .items {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

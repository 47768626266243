.main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0;
  padding: 0;
}
.input {
  all: unset;
  box-sizing: border-box;
  width: 90%;
  font-weight: bold;
  box-shadow: 0px 0px 4px 0px var(--shadow-color);
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  text-align: center;
  margin: 0.5rem 0;
  background-color: var(--main-bg-color);
  /* text-transform: capitalize; */
  color: var(--text-color);
}
.textarea {
  all: unset;
  box-sizing: border-box;
  width: 90%;
  font-weight: bold;
  box-shadow: 0px 0px 4px 0px var(--shadow-color);
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  text-align: left;
  margin: 0.5rem 0;
  min-height: 4rem;
  background-color: var(--main-bg-color);
  /* text-transform: capitalize; */
  color: var(--text-color);
}
.text {
  margin: 0;
  padding: 0;
  color: var(--text-color);
}

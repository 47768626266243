@media all and (min-width: 700px) {
  .main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-color: var(--window-bg-color);
    width: 95%;
    max-width: 800px;
    outline: var(--main-bg-color) 2px solid;
    border-radius: 10px;
    margin: 1rem 0 1rem 0;
    padding-bottom: 1rem;
  }
  .header {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 95%;
  }
  .title {
    font-weight: bold;
    padding: 0.2rem 0.7rem;
    text-transform: uppercase;
    color: var(--text-color);
  }
  .topBox {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
  }
  .list {
    display: grid;
    grid-template-areas: "left right" "left right";
    align-items: flex-start;
    justify-content: center;
    width: 95%;
  }
  .left {
    grid-area: left;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .right {
    grid-area: right;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}
.row {
  width: 90%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  print-color-adjust: exact;
}
@media all and (max-width: 700px) {
  .main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-color: var(--window-bg-color);
    width: 95%;
    border-radius: 10px;
    margin: 1rem 0 1rem 0;
    padding-bottom: 1rem;
  }
  .header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 95%;
  }
  .title {
    font-weight: bold;
    padding: 0.2rem 0.7rem;
    text-transform: uppercase;
    text-align: start;
    color: var(--text-color);
  }
  .topBox {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 95%;
  }
  .list {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 95%;
  }
  .left {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .right {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}
.text {
  color: var(--text-color);
  font-weight: bold;
  text-transform: uppercase;
  padding: 0;
  margin: 0 0 2rem 0;
}
.aseo {
  font-weight: bold;
  padding: 0.2rem 0.7rem;
  text-transform: uppercase;
  white-space: nowrap;
  text-align: end;
  color: var(--text-color);
}

:root {
  --main-bg-color: #d9d9d9;
  --window-bg-color: #fff;
  --bar-color: #2740c0;
  --text-color: #000;
  --link-color: #fff;
  --shadow-color: rgba(0, 0, 0, 0.25);
  --detalle-color: #d9d9d9;
  --tesoros-color: #3c7f8b;
  --maestros-color: #c48430;
  --vida-color: #942926;
}

.App {
  text-align: center;
  background-color: var(--main-bg-color);
  min-height: 100vh;
}
@media print {
  html {
    height: 100%;
  }
  [data-theme="light"] {
    --main-bg-color: #fff;
    --window-bg-color: #eeeeee;
    --bar-color: #2740c0;
    --text-color: #000;
    --link-color: #fff;
    --shadow-color: rgba(0, 0, 0, 0.25);
    --detalle-color: #d9d9d9;
    --tesoros-color: #3c7f8b;
    --maestros-color: #c48430;
    --vida-color: #942926;
  }
  [data-theme="dark"] {
    --main-bg-color: #fff;
    --window-bg-color: #eeeeee;
    --bar-color: #2740c0;
    --text-color: #000;
    --link-color: #fff;
    --shadow-color: rgba(0, 0, 0, 0.25);
    --detalle-color: #d9d9d9;
    --tesoros-color: #3c7f8b;
    --maestros-color: #c48430;
    --vida-color: #942926;
  }
}
[data-theme="light"] {
  --main-bg-color: #d9d9d9;
  --window-bg-color: #fff;
  --bar-color: #2740c0;
  --text-color: #000;
  --link-color: #fff;
  --shadow-color: rgba(0, 0, 0, 0.25);
  --detalle-color: #d9d9d9;
  --tesoros-color: #3c7f8b;
  --maestros-color: #c48430;
  --vida-color: #942926;
}
[data-theme="dark"] {
  --main-bg-color: #000000;
  --window-bg-color: #141414;
  --bar-color: #001e4b;
  --text-color: #fff;
  --link-color: #fff;
  --shadow-color: rgba(85, 85, 85, 0.25);
  --detalle-color: #333;
  --tesoros-color: #3c7f8b;
  --maestros-color: #c48430;
  --vida-color: #942926;
}
/* @media (prefers-color-scheme: dark) {
  :root {
    --main-bg-color: #000000;
    --window-bg-color: #141414;
    --bar-color: #001e4b;
    --text-color: #fff;
    --link-color: #fff;
    --shadow-color: rgba(85, 85, 85, 0.25);
    --detalle-color: #333;
    --tesoros-color: #3c7f8b;
    --maestros-color: #c48430;
    --vida-color: #942926;
  }
} */

.main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.dates {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
@media print {
  .dates {
    display: none;
  }
}

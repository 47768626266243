label {
  all: unset;
  display: flex;
  box-sizing: border-box;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  justify-self: center;
  align-self: center;
  padding: 0;
  width: 40px;
  line-height: 0;
  height: 40px;
  position: relative;
}
input[type="file"] {
  display: none;
}
.filesSelector {
  position: relative;
}
.imgEdit {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin: 0 0.1rem;
  object-fit: cover;
}
.delete {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
}

.window {
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  inset: 0;
  backdrop-filter: blur(2px) brightness(0.7);
  height: 100vh;
  width: 100vw;
}
.content {
  background: var(--window-bg-color);
  width: 80%;
  border-radius: 15px;
  box-shadow: 0px 0px 4px 0px var(--shadow-color);
}
.title {
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--text-color);
}

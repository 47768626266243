.container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.delete {
  background-color: #ff3131;
  color: #fff;
}
.save {
  background-color: #43e98e;
  color: #fff;
}
.btn {
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5rem;
  font-weight: bold;
}
.btn:hover {
  filter: brightness(1.1);
}
@media print {
  .container {
    display: none;
  }
}

.main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--text-color);
}
.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.btn {
  padding: 0.5rem;
  margin: 0 1rem;
  color: var(--text-color);
  background-color: var(--main-bg-color);
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.date {
  text-align: center;
  font-size: 0.9rem;
  margin: 0;
  color: var(--text-color);
  /* background-color: var(--detalle-color); */
  padding: 0;
  width: 100%;
}

.main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 0.1rem;
  width: 50px;
}
.container {
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
}
.img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  object-fit: cover;
  print-color-adjust: exact;
  background-color: var(--shadow-color);
}
.icon {
  position: absolute;
  right: -10px;
  bottom: -8px;
  width: 30px;
  height: 30px;
  object-fit: scale-down;
  padding: 0.2rem;
  filter: drop-shadow(0px 0px 1px #000000);
}
@media all and (min-width: 700px) {
  .text {
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    color: var(--text-color);
  }
  .main {
    margin: 0 .8rem;
  }
}
@media all and (max-width: 700px) {
  .text {
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--text-color);
  }
}

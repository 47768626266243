.main {
  display: grid;
  grid-template-areas: "hora lugar enc aux";
  background-color: var(--window-bg-color);
  padding: 0.7rem 0.5rem;
  border-radius: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  max-width: 500px;
  box-shadow: var(--shadow-color) 0 0 4px;
  justify-content: space-evenly;
  align-self: center;
  justify-self: center;
  align-items: center;
  margin: 0.5rem 0;
}
.img {
  object-fit: cover;
  width: 65px;
  height: 65px;
  border-radius: 10px;
}
.lugar {
  grid-area: lugar;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--text-color);
}
.tipo {
  grid-area: tipo;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
}
.hora {
  grid-area: hora;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.2rem;
  align-self: center;
  justify-self: center;
  text-align: center;
  padding: 0.5rem;
  color: var(--text-color);
}
.enc {
  grid-area: enc;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
}
.aux {
  grid-area: aux;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
}
.detalle {
  grid-area: detalle;
  padding: 0 0.8rem;
  width: 100%;
  min-width: 100%;
  margin: 0;
  border-radius: 10px;
  background-color: var(--detalle-color);
}
.detalle > p {
  margin: 0;
  font-size: 0.9rem;
  text-align: left;
  padding: 0.8rem;
  width: 100%;
  color: var(--text-color);
}
.texto {
  all: unset;
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: capitalize;
  padding: 0.5rem 0.2rem;
  color: var(--text-color);
}
.place {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ffffff9c;
  border-radius: 15px;
}

.box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  margin: 0.5rem;
  background-color: var(--window-bg-color);
  box-shadow: 0px 0px 4px 0px var(--shadow-color);
}
.info {
  width: 75%;
}
.row {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.datoText {
  text-align: left;
  padding: 0;
  white-space: nowrap;
  margin: 0rem 0.5rem 0 0;
  color: var(--text-color);
}
.text {
  text-align: left;
  padding: 0;
  margin: 0.2rem 0;
  color: var(--text-color);
  /* text-transform: capitalize; */
}
.editar {
  width: 20%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

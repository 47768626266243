.main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  width: 90%;
  margin-top: 1rem;
}
.title {
  font-size: 1.2rem;
  color: var(--text-color);
}

.main {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  max-width: 600px;
  margin: 0.5rem;
  background-color: var(--window-bg-color);
  box-shadow: 0px 0px 4px 0px var(--shadow-color);
}

.text {
  text-align: left;
  padding: 0;
  margin: 0.5rem;
  /* width: 100%; */
  white-space: nowrap;
  color: var(--text-color);
  /* text-transform: capitalize; */
}
.textData {
  text-align: left;
  padding: 0;
  margin: 0rem;
  /* width: 100%; */
  color: var(--text-color);
  /* text-transform: capitalize; */
}
.title {
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--text-color);
  background-color: var(--window-bg-color);
  text-align: center;
}
.bg {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
}
.window {
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  inset: 0;
  backdrop-filter: blur(2px) brightness(0.7);
  max-height: 100vh;
  width: 100vw;
}
.question {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.content {
  background: var(--window-bg-color);
  width: 90%;
  border-radius: 15px;
  box-shadow: 0px 0px 4px 0px var(--shadow-color);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding: 1rem;
  height: 80vh;
  overflow-y: auto;
  align-items: center;
}
.check {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--text-color);
  padding: 0.5rem;
}
.item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  color: var(--text-color);
  background: var(--window-bg-color);
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0.5rem 0;
  box-shadow: var(--shadow-color) 0 0 5px;
}
.col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.list {
  display: flex;
  background: var(--shadow-color);
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 700px;
  width: 100%;
  overflow-y: auto;
  padding-top: 1rem;
  box-sizing: border-box;
  box-shadow: var(--shadow-color) 0 0 5px;
}

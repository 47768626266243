.main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--window-bg-color);
  width: 90%;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 2rem 0;
  padding: 0.5rem;
  /* height: 1000px; */
}
.title {
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--text-color);
}

.content {
  background: var(--main-bg-color);
  width: 80%;
  border-radius: 15px;
  box-shadow: 0px 0px 4px 0px var(--shadow-color);
}
.btns {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

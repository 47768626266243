.main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}
.text {
  color: var(--text-color);
  font-size: 1.5rem;
  margin-top: 3rem;
}
.col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-self: flex-end;
  /* min-height: 100vh; */
}
.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 1rem;
  margin: 1rem;
  max-width: 800px;
}
.link {
  text-decoration: none;
  padding: 0.5rem;
  margin: 0 1rem;
  color: var(--link-color);
  border-radius: 10px;
  font-weight: bold;
  background-color: var(--bar-color);
}
.log {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  margin-top: 5rem;
}
.input {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  margin: 0.5rem;
  border: none;
  box-shadow: black 0 0 2px;
}

.main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--window-bg-color);
  width: 95%;
  max-width: 800px;
  border-radius: 10px;
  padding: 0 0rem 1rem 0rem;
  margin: 1rem 0 1rem 0;
  outline: var(--main-bg-color) 2px solid;
}
.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}
.rows {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 95%;
}
.title {
  font-weight: bold;
  padding: 0.2rem 0.7rem;
  text-transform: uppercase;
  color: var(--text-color);
}

.text {
  padding: 0;
  color: var(--text-color);
}
.textS {
  padding: 0.5rem 1rem;
  width: 100%;
  margin: 0;
  font-weight: bold;
  color: var(--text-color);
  backdrop-filter: blur(10px);
}
.especial {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: var(--window-bg-color);
  width: 95%;
  max-width: 800px;
  border-radius: 10px;
  min-height: 3rem;
  padding: 0 0rem 0rem 0rem;
  margin: 1rem 0 1rem 0;
  outline: var(--main-bg-color) 2px solid;
  overflow: hidden;
}
.img {
  width: 100%;
  height: 100%;
  max-height: 8rem;
  object-fit: cover;
  object-position: center;
  border-radius: 10px 10px 0 0;
}

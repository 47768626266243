.nav {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: var(--bar-color);
  box-shadow: 0px 4px 4px 0px var(--shadow-color);
  padding: 0.5rem 1rem;
  overflow-x: auto;
  position: sticky;
  top: 0;
  z-index: 50;
}
.link {
  all: unset;
  color: var(--link-color);
  font-weight: bold;
  padding: 0.5rem;
  margin: 0 0.4rem;
  border-radius: 5px;
  cursor: pointer;
  min-width: fit-content;
}
.active {
  all: unset;
  color: var(--link-color);
  font-weight: bold;
  padding: 0.5rem;
  margin: 0 0.4rem;
  border-radius: 5px;
  cursor: pointer;
  min-width: fit-content;
  background-color: var(--shadow-color);
}
.link:active {
  background-color: var(--shadow-color);
}

@media print {
  .nav {
    display: none;
  }
}

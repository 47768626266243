.main {
  width: 90%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.25rem;
}
.img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  object-position: center;
  print-color-adjust: exact;
}
.text {
  margin: 0 0.5rem;
  font-size: 1rem;
  color: var(--text-color);
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
}

@media all and (max-width: 800px) {
  .list {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    border-radius: 15px;
    /* box-shadow: 0px 0px 4px 0px var(--shadow-color); */
    background-color: var(--window-bg-color);
    /* overflow-y: auto; */
    margin: 0rem 0;
    padding: 1rem 0rem;
  }
  .info {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  .privilegios {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  .discursos {
    display: flex;
    width: 95%;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .canciones {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  .box {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  .check {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin: 5rem 0 0 0;
    padding: 0;
    color: var(--text-color);
  }
  .text {
    color: var(--text-color);
    width: 90%;
  }
  label {
    all: unset;
    display: flex;
    box-sizing: border-box;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 40px;
    line-height: 0;
    height: 40px;
  }
  input[type="file"] {
    display: none;
  }

  .imgEdit {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin: 0 0.1rem;
    object-fit: cover;
  }
  .checkbox {
    color: var(--text-color);
  }
}
@media screen and (min-width: 800px) {
  .list {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 15px;
    box-shadow: 0px 0px 4px 0px var(--shadow-color);
    background-color: var(--window-bg-color);
    /* overflow-y: auto; */
    margin: 2rem 0;
    padding: 1rem 0rem;
  }
  .text {
    color: var(--text-color);
    width: 90%;
  }
  .info {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
  .privilegios {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  .discursos {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
  }
  .canciones {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  .box {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  .check {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 2rem;
    color: var(--text-color);
  }
  .title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin: 5rem 0 0 0;
    padding: 0;
    color: var(--text-color);
  }
  label {
    all: unset;
    display: flex;
    box-sizing: border-box;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 40px;
    line-height: 0;
    height: 40px;
  }
  input[type="file"] {
    display: none;
  }
  .imgEdit {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin: 0 0.1rem;
    object-fit: cover;
  }
  .checkbox {
    color: var(--text-color);
  }
}
.btns {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  margin: 1rem 0 0 0;
  border-radius: 5px;
  display: flex;
  padding: 0.5rem 0;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--window-bg-color);
}
.delete {
  width: 40px;
  height: 40px;
}

:root {
  --scale-factor: 1;
}
.main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  align-self: center;
  justify-self: center;
  transform: scale(var(--scale-factor));
}
.title {
  margin-top: 3rem;
  font-weight: bold;
  color: var(--text-color);
  font-size: 1.4rem;
}


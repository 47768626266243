@media all and (max-width: 600px) {
  .main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    width: 100%;
    margin: 1rem;
  }
}
@media all and (min-width: 600px) {
  .main {
    display: flex;
    flex-flow: row nowrap;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: flex-start;
    width: 95%;
    margin: 1rem 0;
    border-radius: 10px;
    padding: 1rem 0;
    /* outline: var(--main-bg-color) 2px solid; */
  }
  .main:nth-child(odd) {
    break-after: page;
  }
}

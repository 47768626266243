.main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  padding: 2rem;
}
.title {
  margin-top: 3rem;
  font-weight: bold;
  font-size: 1.4rem;
  color: var(--text-color);
}
.linkContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.link {
  margin: 0.5rem;
  color: #fff;
  text-decoration: none;
  background-color: var(--bar-color);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}
.link:hover {
  filter: brightness(1.2);
}
.img {
  width: 100%;
  object-fit: contain;
  border-radius: 15px;
  margin: 0rem;
  padding: 0;
}
.text {
  margin-top: 2rem;
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--text-color);
}

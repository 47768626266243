.container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0rem 0;
  padding: 0;
}
.input {
  all: unset;
  background-color: var(--detalle-color);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  width: 200px;
  border-radius: 5px;
  padding: 0.5rem 0;
  margin: 0;
  text-align: center;
  color: var(--text-color);
}
.title {
  color: var(--text-color);
}
.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.btn {
  padding: 0.5rem;
  margin: 0 1rem;
  color: var(--text-color);
  background-color: var(--detalle-color);
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

@media all and (max-width: 600px) {
  .main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    width: 90%;
    margin-top: 1rem;
  }
  .title {
    font-size: 1.2rem;
    color: var(--text-color);
  }
}
@media all and (min-width: 600px) {
  .main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 0.2rem;
    width: 100px;
  }
  .title {
    font-size: 0.75rem;
    width: 95%;
    height: 2rem;
    color: var(--text-color);
  }
}

.main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--window-bg-color);
  width: 90%;
  max-width: 600px;
  border-radius: 10px;
  margin: 2rem 0;
  height: 600px;
}
.title {
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--text-color);
  background-color: var(--window-bg-color);
  text-align: center;
}
.bg {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
}
.dato {
  background-color: var(--shadow-color);
  padding: 0 0.5rem;
  border-radius: 5px;
  margin: 0.5rem;
  cursor: pointer;
}
.dato:hover {
  background-color: var(--main-bg-color);
}
.window {
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  inset: 0;
  backdrop-filter: blur(2px) brightness(0.7);
  max-height: 100vh;
  width: 100vw;
}
.content {
  background: var(--window-bg-color);
  width: 90%;
  border-radius: 15px;
  box-shadow: 0px 0px 4px 0px var(--shadow-color);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding: 1rem;
  height: 80vh;
  overflow-y: auto;
  align-items: center;
}
.list {
  overflow-y: auto;
  width: 90%;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 300px;
  background-color: var(--main-bg-color);
  box-shadow: 0px 0px 4px 0px var(--shadow-color);
}
.check {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--text-color);
  padding: 0.5rem;
}
.box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.delete {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
}
.imgPreview {
  width: 90%;
  height: 90%;
  max-height: 200px;
  padding: 0.5rem;
  margin: 0.5rem;
  background-color: #aaa;
  object-fit: cover;
  border: black 1px solid;
  border-radius: 5px;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0rem;
}

.main {
  width: 90%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0.1rem;
}
.img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 5px;
}
.text {
  margin: 0 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--text-color);
}

.main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 0;
  width: 100%;
  min-height: 100vh;
}
.title {
  font-size: 1.5rem;
  color: var(--text-color);
}

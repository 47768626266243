@media all and (max-width: 600px) {
  .main {
    display: grid;
    grid-template-areas: "hora lugar tipo enc" "detalle detalle detalle detalle" "datos datos datos datos";
    background-color: var(--window-bg-color);
    padding: 0.7rem 0.5rem;
    border-radius: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    box-shadow: 0px 0px 5px var(--shadow-color);
    justify-content: space-evenly;
    align-self: center;
    justify-self: center;
    align-items: center;
    margin: 0.5rem 0;
  }
  .event {
    display: grid;
    grid-template-areas: "tipo" "detalle";
    background-color: var(--window-bg-color);
    padding: 0.7rem 0.5rem;
    border-radius: 10px;
    grid-template-columns: 1fr;
    width: 100%;
    box-shadow: var(--shadow-color) 0 0 4px;
    justify-content: space-evenly;
    align-self: center;
    justify-self: center;
    align-items: center;
    margin: 0.5rem 0;
  }

  .img {
    object-fit: cover;
    width: 65px;
    height: 65px;
    border-radius: 10px;
  }
  .imgEvent {
    object-fit: cover;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 10px 10px 10px 10px;
  }
  .lugar {
    grid-area: lugar;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .tipo {
    grid-area: tipo;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .hora {
    grid-area: hora;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.2rem;
    align-self: center;
    justify-self: center;
    text-align: center;
    padding: 0.5rem;
    color: var(--text-color);
  }
  .enc {
    grid-area: enc;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .datos {
    grid-area: datos;
    display: flex;
    padding: 0 0.8rem;
    width: 100%;
    min-width: 100%;
    margin: 0;
    border-radius: 10px;
    background-color: var(--detalle-color);
  }
  .dato {
    margin: 0;
    font-size: 0.9rem;
    text-align: left;
    padding: 0.8rem;
    color: var(--text-color);
    width: 100%;
  }
  .detalle {
    grid-area: detalle;
    padding: 0 0.8rem;
    width: 100%;
    min-width: 100%;
    margin: 0;
    border-radius: 10px;
    background-color: var(--detalle-color);
  }
  .detalle > p {
    margin: 0;
    font-size: 0.9rem;
    text-align: left;
    padding: 0.8rem;
    color: var(--text-color);
    width: 100%;
  }
  .detalleEvent {
    grid-area: detalle;
    padding: 0 0.8rem;
    width: 100%;
    min-width: 100%;
    margin: 0;
    border-radius: 0 0 10px 10px;
    background-color: var(--window-bg-color);
  }
  .detalleEvent > p {
    margin: 0;
    font-size: 0.9rem;
    text-align: center;
    padding: 0.8rem;
    width: 100%;
    color: var(--text-color);
  }

  .texto {
    all: unset;
    font-size: 0.9rem;
    font-weight: bold;
    color: var(--text-color);
    text-transform: capitalize;
    padding: 0.5rem 0.2rem;
  }
  .place {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 0;
    top: 0;
    /* background-color: var(--shadow-color); */
    /* border-radius: 15px; */
  }
  .link {
    text-decoration: none;
    margin: 0.5rem;
    padding: 0.5rem 0.5rem;
    font-size: 0.8rem;
    background-color: rgb(80, 235, 150);
    color: var(--text-color);
    border-radius: 10px;
    box-shadow: 0px 0px 5px var(--shadow-color);
  }
}

@media all and (min-width: 600px) {
  .main {
    display: grid;
    grid-template-areas: "hora tipo" "lugar enc" "detalle detalle" "datos datos";
    background-color: var(--window-bg-color);
    padding: 0.5rem 0.5rem 0.2rem 0.5rem;
    width: 100%;
    border-radius: 5px;
    /* max-width: 400px; */
    justify-content: center;
    align-items: center;
    margin: 0.25rem 0;
    print-color-adjust: exact;
    outline: var(--main-bg-color) 2px solid;
  }
  .event {
    display: grid;
    grid-template-areas: "tipo" "detalle";
    background-color: var(--window-bg-color);
    padding: 0.5rem 0.5rem 0.2rem 0.5rem;
    width: 100%;
    border-radius: 5px;
    /* max-width: 400px; */
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0;
  }
  .img {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  .imgEvent {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    cursor: pointer;
  }
  .lugar {
    grid-area: lugar;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color);
  }
  .tipo {
    grid-area: tipo;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
  }
  .hora {
    grid-area: hora;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
    align-self: center;
    justify-self: center;
    text-align: center;
    padding: 0.2rem;
    margin: 0;
    color: var(--text-color);
  }
  .enc {
    grid-area: enc;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
  }
  .detalle {
    grid-area: detalle;
    padding: 0.2rem;
    width: 100%;
    align-self: center;
    justify-self: center;
    border-radius: 10px;
    /* background-color: var(--main-bg-color); */
    /* background-color: #dfdfdf; */
  }
  .detalle > p {
    text-align: center;
    width: 100%;
    align-self: center;
    justify-self: center;
    border-radius: 10px;
    font-size: 0.6rem;
    padding: 0;
    margin: 0;
    color: var(--text-color);
    /* background-color: #dfdfdf; */
  }
  .detalleEvent {
    grid-area: detalle;
    padding: 0.2rem;
    width: 100%;
    align-self: center;
    justify-self: center;
  }
  .detalleEvent > p {
    text-align: center;
    width: 100%;
    align-self: center;
    justify-self: center;
    border-radius: 10px;
    font-size: 0.6rem;
    padding: 0;
    margin: 0;
    color: var(--text-color);
  }
  .datos {
    grid-area: datos;
    padding: 0.2rem;
    width: 100%;
    align-self: center;
    justify-self: center;
    border-radius: 10px;
  }
  .dato {
    text-align: center;
    width: 100%;
    align-self: center;
    justify-self: center;
    border-radius: 10px;
    font-size: 0.6rem;
    padding: 0;
    margin: 0;
    color: var(--text-color);
  }
  .texto {
    all: unset;
    font-size: 0.6rem;
    font-weight: bold;
    text-transform: capitalize;
    margin: 0;
    padding: 0;
    color: var(--text-color);
  }
  .place {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--shadow-color);
    border-radius: 15px;
  }
  .link {
    display: none;
  }
}
@media print and (min-width: 600px) {
  .main {
    background-color: var(--window-bg-color);
    print-color-adjust: exact;
  }
}

@media screen, print {
  .main {
    width: 90%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0.25rem 0;
    padding: 0;
    print-color-adjust: exact;
  }
  .id {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .number {
    width: 40px;
    height: 40px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
  .numberEdit {
    width: 40px;
    height: 40px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  .img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin: 0 0.1rem;
    object-fit: cover;
  }
  .imgEdit {
    width: 40px;
    height: 40px;
    object-fit: cover;
    cursor: pointer;
  }
  .text {
    margin: 0 0.2rem;
    font-size: 1rem;
    padding: 0 0.1rem;
    width: 100%;
    text-align: left;
    color: var(--text-color);
  }
  .tesoros {
    background: var(--tesoros-color);
    border-radius: 5px;
  }
  .maestros {
    background-color: var(--maestros-color);
    border-radius: 5px;
  }
  .vida {
    background-color: var(--vida-color);
    border-radius: 5px;
  }
  .inputs {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    width: 120px;
    align-items: center;
  }
  .editable {
    box-sizing: border-box;
    max-width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0rem;
    padding: 0 0.2rem;
    overflow-x: hidden;
    print-color-adjust: exact;
  }
  .idEditable {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .apoyo {
    position: absolute;
    bottom: -1.8rem;
    right: 0;
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--text-color);
  }
  label {
    all: unset;
    display: flex;
    box-sizing: border-box;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 40px;
    line-height: 0;
    height: 40px;
  }
  input[type="file"] {
    display: none;
  }
  .delete {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
  }
}

.main {
  padding: 0.2rem;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
}
.link {
  display: flex;
  flex-flow: row nowrap;
  text-decoration: none;
  color: var(--text-color);
  padding: 0.5rem;
  font-size: 0.8rem;
  cursor: pointer;
}
@media print {
  .main {
    display: none;
  }
}
